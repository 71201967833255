<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
      @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav" />
    <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav" />

    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <div style="height: 100%" class="right-menu-item hover-effect">
          <el-link type="primary" href="/addon/xlgm_cs" target="_blank" size="small" style="padding: 0 20px"
            v-if="deptId === 100">
            测试
          </el-link>
          <el-dropdown v-if="deptId === 100" trigger="click">
            <el-link type="primary" target="_blank" size="small" style="padding: 0 20px" v-if="deptId === 100">
              运输在线监管
            </el-link>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-link type="primary" href="/addon/xlgm" target="_blank" size="small" style="padding: 0 20px">
                  兴龙工贸
                </el-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-link type="primary" href="/addon/daping-tfjg" target="_blank" size="small" style="padding: 0 20px">
                  土方监管
                </el-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-link v-else-if="deptId === 347" type="primary" href="/addon/xlgm" target="_blank" size="small"
            style="padding: 0 20px">
            运输在线监管
          </el-link>
          <el-link v-else-if="deptId === 291" type="primary" href="/addon/daping-tfjg" target="_blank" size="small"
            style="padding: 0 20px">
            运输在线监管
          </el-link>
          <el-link v-else type="primary" href="/addon/xlgm" target="_blank" size="small" style="padding: 0 20px">
            运输在线监管
          </el-link>
          <el-link v-if="deptId === 323" type="primary" href="/addon/daping_casd" target="_blank" size="small">
            数据大屏
          </el-link>
          <el-link v-else type="primary" href="/addon/business_overview" target="_blank" size="small">
            数据大屏
          </el-link>
        </div>
        <search id="header-search" class="right-menu-item" />

        <screenfull id="screenfull" class="right-menu-item hover-effect" />

        <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>
        <el-tooltip content="站内信" effect="dark" placement="bottom">
          <div style="height: 100%" class="right-menu-item" @click="open = true">
            <el-badge is-dot class="item">
              <svg-icon icon-class="mail" class-name="mail-icon" />
            </el-badge>
          </div>
        </el-tooltip>
      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar" class="user-avatar" />
          <div>{{ $store.state.user.nickName }}</div>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user/profile">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <!--          <el-dropdown-item @click.native="open1=true">-->
          <!--            <span>系统设置</span>-->
          <!--          </el-dropdown-item>-->
          <el-dropdown-item @click.native="setting = true">
            <span>布局设置</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <mail-info v-model="open" />
    <system-settings v-model="open1" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import TopNav from "@/components/TopNav";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
import Search from "@/components/HeaderSearch";
import mailInfo from "@/views/tms/fhsms/mailInfo";
import systemSettings from "@/layout/components/Settings/systemSettings";

export default {
  data() {
    return {
      open: false,
      open1: false,
    };
  },
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    mailInfo,
    systemSettings,
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "device", "deptId"]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val,
        });
      },
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav;
      },
    },
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("LogOut").then(() => {
            location.href = "/index";
          });
        })
        .catch(() => { });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        //margin-top: 5px;
        position: relative;
        display: flex;
        align-items: center;

        div {
          padding-left: 5px;
          font-size: 14px;
          max-width: 95px;
          height: 50px;
          overflow: hidden;
        }

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -15px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

.mail-icon {
  cursor: pointer;
  font-size: 23px;
  vertical-align: middle;
  color: #5a5e66;
}

.item {
  margin-top: 2px;
}

::v-deep .el-badge__content.is-fixed.is-dot {
  top: 13px;
}
::v-deep .el-link.is-underline:hover:after {
  border: none;
}
</style>
