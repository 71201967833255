var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      !_vm.topNav
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          })
        : _vm._e(),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "right-menu-item hover-effect",
                    staticStyle: { height: "100%" },
                  },
                  [
                    _vm.deptId === 100
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { padding: "0 20px" },
                            attrs: {
                              type: "primary",
                              href: "/addon/xlgm_cs",
                              target: "_blank",
                              size: "small",
                            },
                          },
                          [_vm._v(" 测试 ")]
                        )
                      : _vm._e(),
                    _vm.deptId === 100
                      ? _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _vm.deptId === 100
                              ? _c(
                                  "el-link",
                                  {
                                    staticStyle: { padding: "0 20px" },
                                    attrs: {
                                      type: "primary",
                                      target: "_blank",
                                      size: "small",
                                    },
                                  },
                                  [_vm._v(" 运输在线监管 ")]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        staticStyle: { padding: "0 20px" },
                                        attrs: {
                                          type: "primary",
                                          href: "/addon/xlgm",
                                          target: "_blank",
                                          size: "small",
                                        },
                                      },
                                      [_vm._v(" 兴龙工贸 ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-dropdown-item",
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        staticStyle: { padding: "0 20px" },
                                        attrs: {
                                          type: "primary",
                                          href: "/addon/daping-tfjg",
                                          target: "_blank",
                                          size: "small",
                                        },
                                      },
                                      [_vm._v(" 土方监管 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.deptId === 347
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { padding: "0 20px" },
                            attrs: {
                              type: "primary",
                              href: "/addon/xlgm",
                              target: "_blank",
                              size: "small",
                            },
                          },
                          [_vm._v(" 运输在线监管 ")]
                        )
                      : _vm.deptId === 291
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { padding: "0 20px" },
                            attrs: {
                              type: "primary",
                              href: "/addon/daping-tfjg",
                              target: "_blank",
                              size: "small",
                            },
                          },
                          [_vm._v(" 运输在线监管 ")]
                        )
                      : _c(
                          "el-link",
                          {
                            staticStyle: { padding: "0 20px" },
                            attrs: {
                              type: "primary",
                              href: "/addon/xlgm",
                              target: "_blank",
                              size: "small",
                            },
                          },
                          [_vm._v(" 运输在线监管 ")]
                        ),
                    _vm.deptId === 323
                      ? _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              href: "/addon/daping_casd",
                              target: "_blank",
                              size: "small",
                            },
                          },
                          [_vm._v(" 数据大屏 ")]
                        )
                      : _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              href: "/addon/business_overview",
                              target: "_blank",
                              size: "small",
                            },
                          },
                          [_vm._v(" 数据大屏 ")]
                        ),
                  ],
                  1
                ),
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" },
                }),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "布局大小",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("size-select", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "size-select" },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "站内信",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "right-menu-item",
                        staticStyle: { height: "100%" },
                        on: {
                          click: function ($event) {
                            _vm.open = true
                          },
                        },
                      },
                      [
                        _c(
                          "el-badge",
                          { staticClass: "item", attrs: { "is-dot": "" } },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "mail",
                                "class-name": "mail-icon",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar },
                }),
                _c("div", [_vm._v(_vm._s(_vm.$store.state.user.nickName))]),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/user/profile" } },
                    [_c("el-dropdown-item", [_vm._v("个人中心")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.setting = true
                        },
                      },
                    },
                    [_c("span", [_vm._v("布局设置")])]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [_c("span", [_vm._v("退出登录")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("mail-info", {
        model: {
          value: _vm.open,
          callback: function ($$v) {
            _vm.open = $$v
          },
          expression: "open",
        },
      }),
      _c("system-settings", {
        model: {
          value: _vm.open1,
          callback: function ($$v) {
            _vm.open1 = $$v
          },
          expression: "open1",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }