import Cookies from 'js-cookie'
import {getSiteInfo} from "@/api/login";

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: Cookies.get('size') || 'medium',
  siteName: Cookies.get('siteName')||'网络货运平台',
  beianCode: Cookies.get('beianCode'),
  siteLogo: Cookies.get('siteLogo'),
  siteUrl: Cookies.get('siteUrl'),
  register: Cookies.get('register'),
  firstOpen: Cookies.get('firstOpen'),
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SITE_NAME: (state, siteName) => {
    state.siteName = siteName
    Cookies.set('siteName', siteName)
  },
  BEIAN_CODE: (state, beianCode) => {
    state.beianCode = beianCode
    Cookies.set('beianCode', beianCode)
  },
  SITE_LOGO: (state, siteLogo) => {
    state.siteLogo = siteLogo
    Cookies.set('siteLogo', siteLogo)
  },
  SITE_URL: (state, siteUrl) => {
    state.siteUrl = siteUrl
    Cookies.set('siteUrl', siteUrl)
  },
  REGISTER: (state, register) => {
    state.register = register
    Cookies.set('register', register)
  },
  FIRST_OPEN: (state, firstOpen) => {
    state.firstOpen = firstOpen
    Cookies.set('firstOpen', firstOpen)
  },
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  setFirstOpen({ commit }, flag) {
    commit('FIRST_OPEN', flag)
  },
  // 获取用户信息
  GetSiteInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getSiteInfo().then(res => {
        let siteInfo = res.data;
        //清空缓存
        // Cookies.remove("siteName");
        // Cookies.remove("beianCode");
        // Cookies.remove("siteLogo");
        commit('SITE_NAME', siteInfo.siteName)
        commit('BEIAN_CODE', siteInfo.beianCode)
        commit('SITE_LOGO', siteInfo.siteLogo)
        commit('SITE_URL', siteInfo.siteUrl)
        commit('REGISTER', siteInfo.params.register)
        commit('FIRST_OPEN', 1)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
