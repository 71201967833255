<template>
  <el-dialog title="站内信" :visible.sync="value" width="1000px" :before-close="cancel" append-to-body>
    <fhsms/>
  </el-dialog>
</template>

<script>
import fhsms from "@/views/tms/fhsms";

export default {
  name: "mailInfo",
  props: {
    value: Boolean
  },
  components: {
    fhsms
  },
  data() {
    return {}
  },
  methods: {
    cancel() {
      this.$emit("input", false);
    },
  }
}
</script>

<style scoped>

</style>
