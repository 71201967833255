<template>
  <el-dialog
    title="系统设置"
    :visible.sync="value"
    width="500"
    :before-close="cancel"
    append-to-body>
    <el-form ref="form" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="司机审核接单">
            <el-switch v-model="driverCheck" active-color="#13ce66" inactive-color="#ff4949"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电子签">
            <el-switch v-model="esignFlag" active-color="#13ce66" inactive-color="#ff4949"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

  </el-dialog>
</template>

<script>
import {listConfig} from "@/api/system/config";

export default {
  name: "systemSettings",
  props: {
    value: Boolean
  },
  data() {
    return {
      driverCheck: 0,
      esignFlag: 0
    }
  },
  created() {
    // this.driverCheck=true;
  },
  methods: {
    getList() {
      this.loading = true;
      listConfig().then(response => {
          this.configList = response.rows;
          for (let i = 0; i < this.configList.length; i++) {
            if(this.configList[i].configKey==='DRIVER_CHECK'){
              this.driverCheck = this.configList[i].configValue;
            }
          }
        }
      );
    },
    cancel: function () {
      this.$emit('input', false);
    },
  }
}
</script>

<style scoped>

</style>
