var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "系统设置",
        visible: _vm.value,
        width: "500",
        "before-close": _vm.cancel,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.value = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "100px" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "司机审核接单" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                        },
                        model: {
                          value: _vm.driverCheck,
                          callback: function ($$v) {
                            _vm.driverCheck = $$v
                          },
                          expression: "driverCheck",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "电子签" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                        },
                        model: {
                          value: _vm.esignFlag,
                          callback: function ($$v) {
                            _vm.esignFlag = $$v
                          },
                          expression: "esignFlag",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }