var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "站内信",
        visible: _vm.value,
        width: "1000px",
        "before-close": _vm.cancel,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.value = $event
        },
      },
    },
    [_c("fhsms")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }