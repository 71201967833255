import request from '@/utils/request'

// 查询站内信列表
export function listFhsms(query) {
  return request({
    url: '/tms/fhsms/list',
    method: 'get',
    params: query
  })
}

// 查询站内信详细
export function getFhsms(fhsmsId) {
  return request({
    url: '/tms/fhsms/' + fhsmsId,
    method: 'get'
  })
}

// 新增站内信
export function addFhsms(data) {
  return request({
    url: '/tms/fhsms',
    method: 'post',
    data: data
  })
}

// 修改站内信
export function updateFhsms(data) {
  return request({
    url: '/tms/fhsms',
    method: 'put',
    data: data
  })
}

// 删除站内信
export function delFhsms(fhsmsId) {
  return request({
    url: '/tms/fhsms/' + fhsmsId,
    method: 'delete'
  })
}

// 导出站内信
export function exportFhsms(query) {
  return request({
    url: '/tms/fhsms/export',
    method: 'get',
    params: query
  })
}