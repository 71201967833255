import Vue from 'vue'

import Cookies from 'js-cookie'

import Element from 'element-ui'
import './assets/styles/element-variables.scss'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import App from './App'
import store from './store'
import router from './router'
import directive from './directive' // directive
import plugins from './plugins' // plugins

// 引入echarts
import echarts from 'echarts'
import './assets/icons' // icon
import './permission' // permission control
import { getDicts } from '@/api/system/dict/data'
import { getConfigKey } from '@/api/system/config'
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from '@/utils/ruoyi'
// 分页组件
import Pagination from '@/components/Pagination'
// 自定义表格工具组件
import RightToolbar from '@/components/RightToolbar'
// 富文本组件
import Editor from '@/components/Editor'
// 文件上传组件
import FileUpload from '@/components/FileUpload'
// 图片上传组件
import ImageUpload from '@/components/ImageUpload'
// 字典标签组件
import DictTag from '@/components/DictTag'
// 头部标签组件
import VueMeta from 'vue-meta'
// 字典数据组件
import DictData from '@/components/DictData'
// 百度地图
import BaiduMap from 'vue-baidu-map'

// 高德地图
import VueAMap from 'vue-amap';
Vue.use(VueAMap);

// 初始化vue-amap(高德地图组件)
VueAMap.initAMapApiLoader({
  // 高德的key
  // key: '14bdb3b7a3bc1f5eb1b44a0711265472', // map
  // key: '0d9850a9f53aafb7ad5eabb28a32e682',
  key: '10a991028ad3f967b6ec72780030bf0c',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor',
    'AMap.CircleEditor', 'AMap.MarkerClusterer', 'AMap.MouseTool', 'AMap.ContextMenu', 'AMap.Marker', 'AMp.InfoWindow', 'AMap.Geocoder',
    'AMap.Driving','AMap.DrivingRender','AMap.MoveAnimation', 'AMap.Label', 'AMap.InfoWindow'
  ],
  // 高德 sdk版本
  v: '1.4.4'
})

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree
Vue.prototype.$echarts = echarts

// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('Editor', Editor)
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)

Vue.use(directive)
Vue.use(plugins)
Vue.use(VueMeta)
DictData.install()
// 百度地图JavascriptAPI的AK
Vue.use(BaiduMap, {
  ak: ''
})
// 跨域请求支持
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

import formCreate from '@form-create/element-ui'
Vue.use(formCreate)

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.config.productionTip = false

  // if (!!store && !!store.getters && !!store.getters.permissions && store.getters.permissions.length == 1 && store.getters.permissions[0] == 'tms:waybill:testWayList') {
  //   console.log("router", router)
  //   if (!!router && router.options) {
  //     let rou = 
  //   }
  // }

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
