<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="类型" prop="type">
        <el-select v-model="queryParams.type" placeholder="请选择类型" clearable size="small">
          <el-option
            v-for="dict in dict.type.mail_type"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="收信人" prop="toUsername" v-has-role="['admin','admin1']">
        <el-input
          v-model="queryParams.toUsername"
          placeholder="请输入收信人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="发信人" prop="fromUsername" v-has-role="['admin','admin1']">
        <el-input
          v-model="queryParams.fromUsername"
          placeholder="请输入发信人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="发信时间" prop="sendTime" v-has-role="['admin','admin1']" v-show="showQuery">
        <el-date-picker clearable size="small"
                        v-model="queryParams.sendTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择发信时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态" prop="status" v-show="showQuery">
        <el-select v-model="queryParams.status" placeholder="请选择状态" clearable size="small">
          <el-option
            v-for="dict in dict.type.mail_status"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        <el-button icon="el-icon-arrow-up" size="mini" v-if="showQuery" @click="showQuery=!showQuery">收回</el-button>
        <el-button icon="el-icon-arrow-down" size="mini" v-else @click="showQuery=!showQuery">展开</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <!--      <el-col :span="1.5">-->
      <!--        <el-button-->
      <!--          type="primary"-->
      <!--          plain-->
      <!--          icon="el-icon-plus"-->
      <!--          size="mini"-->
      <!--          @click="handleAdd"-->
      <!--        >新增-->
      <!--        </el-button>-->
      <!--      </el-col>-->
      <!--      <el-col :span="1.5">-->
      <!--        <el-button-->
      <!--          type="success"-->
      <!--          plain-->
      <!--          icon="el-icon-edit"-->
      <!--          size="mini"-->
      <!--          :disabled="single"-->
      <!--          @click="handleUpdate"-->
      <!--        >修改-->
      <!--        </el-button>-->
      <!--      </el-col>-->
      <!--      <el-col :span="1.5">-->
      <!--        <el-button v-has-role="['admin','admin1']"-->
      <!--          type="danger"-->
      <!--          plain-->
      <!--          icon="el-icon-delete"-->
      <!--          size="mini"-->
      <!--          :disabled="multiple"-->
      <!--          @click="handleDelete"-->
      <!--        >删除-->
      <!--        </el-button>-->
      <!--      </el-col>-->
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          :loading="exportLoading"
          @click="handleExport"
        >导出
        </el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="fhsmsList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="序号" align="center" type="index"/>
      <el-table-column label="内容" align="center" prop="content" :show-overflow-tooltip="true"/>
      <el-table-column label="类型" align="center" prop="type">
        <template slot-scope="scope">
          <dict-tag :options="dict.type.mail_type" :value="scope.row.type"/>
        </template>
      </el-table-column>
      <el-table-column label="收信人" align="center" prop="toUsername"/>
      <el-table-column label="发信人" align="center" prop="fromUsername"/>
      <el-table-column label="发信时间" align="center" prop="sendTime" width="180"/>
      <el-table-column label="状态" align="center" prop="status">
        <template slot-scope="scope">
          <dict-tag :options="dict.type.mail_status" :value="scope.row.status"/>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >查看
          </el-button>
          <!--          <el-button v-has-role="['admin','admin1']"-->
          <!--            size="mini"-->
          <!--            type="text"-->
          <!--            icon="el-icon-delete"-->
          <!--            @click="handleDelete(scope.row)"-->
          <!--          >删除-->
          <!--          </el-button>-->
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改站内信对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <editor v-model="form.content" :min-height="192"/>
        <!--        <el-form-item label="内容">-->
        <!--          <editor v-model="form.content" :min-height="192"/>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="类型" prop="type">-->
        <!--          <el-select v-model="form.type" placeholder="请选择类型">-->
        <!--            <el-option-->
        <!--              v-for="dict in dict.type.mail_type"-->
        <!--              :key="dict.value"-->
        <!--              :label="dict.label"-->
        <!--              :value="dict.value"-->
        <!--            ></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="收信人" prop="toUsername">-->
        <!--          <el-input v-model="form.toUsername" placeholder="请输入收信人"/>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="发信人" prop="fromUsername">-->
        <!--          <el-input v-model="form.fromUsername" placeholder="请输入发信人"/>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="发信时间" prop="sendTime">-->
        <!--          <el-date-picker clearable size="small"-->
        <!--                          v-model="form.sendTime"-->
        <!--                          type="date"-->
        <!--                          value-format="yyyy-MM-dd"-->
        <!--                          placeholder="选择发信时间">-->
        <!--          </el-date-picker>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="状态">-->
        <!--          <el-radio-group v-model="form.status">-->
        <!--            <el-radio-->
        <!--              v-for="dict in dict.type.mail_status"-->
        <!--              :key="dict.value"-->
        <!--              :label="dict.value"-->
        <!--            >{{ dict.label }}-->
        <!--            </el-radio>-->
        <!--          </el-radio-group>-->
        <!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!--        <el-button type="primary" @click="submitForm">确 定</el-button>-->
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {listFhsms, getFhsms, delFhsms, addFhsms, updateFhsms, exportFhsms} from "@/api/tms/fhsms";

export default {
  name: "Fhsms",
  dicts: ['mail_type', 'mail_status'],
  data() {
    return {
      // 遮罩层
      loading: true,
      // 导出遮罩层
      exportLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      showQuery: false,
      // 总条数
      total: 0,
      // 站内信表格数据
      fhsmsList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: null,
        toUsername: null,
        fromUsername: null,
        sendTime: null,
        status: null,
        sanmeId: null
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {}
    };
  },
  created() {
    this.queryParams.type = "1";
    this.getList();
  },
  methods: {
    /** 查询站内信列表 */
    getList() {
      this.loading = true;
      listFhsms(this.queryParams).then(response => {
        this.fhsmsList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.getList();
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        fhsmsId: null,
        content: null,
        type: null,
        toUsername: null,
        fromUsername: null,
        sendTime: null,
        status: "0",
        sanmeId: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.fhsmsId)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加站内信";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const fhsmsId = row.fhsmsId || this.ids
      getFhsms(fhsmsId).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "查看站内信";
        //更改为已读状态
        updateFhsms(this.form).then(response => {
          console.log("读取成功");
        });
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.fhsmsId != null) {
            updateFhsms(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addFhsms(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const fhsmsIds = row.fhsmsId || this.ids;
      this.$modal.confirm('是否确认删除站内信编号为"' + fhsmsIds + '"的数据项？').then(function () {
        return delFhsms(fhsmsIds);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      }).catch(() => {
      });
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$modal.confirm('是否确认导出所有站内信数据项？').then(() => {
        this.exportLoading = true;
        return exportFhsms(queryParams);
      }).then(response => {
        this.$download.name(response.msg);
        this.exportLoading = false;
      }).catch(() => {
      });
    }
  }
};
</script>
