<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
      titleTemplate: title => {
          return title ? `${title} - ${this.$store.getters.siteName}` : this.$store.getters.siteName
      }
    }
  }
}
</script>
