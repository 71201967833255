import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect', '/bind', '/register','/download','/monitor']

router.beforeEach((to, from, next) => {
  // 测试环境蓝色背景，正式环境白色背景
  const backgroundColor =  process.env.NODE_ENV === "production" ? 'white':"rgb(156, 220, 218)";
  window.document.body.style.backgroundColor = backgroundColor;
  NProgress.start();
  if (getToken()) {
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => {
          store.dispatch('GenerateRoutes').then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            router.addRoutes(accessRoutes) // 动态添加可访问路由表
            // next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            // 测试运单使用(当只有一个权限并且是运单列表时)
            if (!!store.getters && store.getters.permissions.length == 1 && store.getters.permissions[0] == 'tms:waybill:testWayList') {
              // console.log("特殊", router);
              let path = '';
              path = accessRoutes[0].path + '/' + accessRoutes[0].children[0].path;
              // if (from.path == '/login' || from.path == '/') {
              if (to.path === '/' || to.path === '/index') {
                next({path, replace: true})
              } else {
                next({...to, replace: true})
              }
            } else {
              // console.log("正常", LayoutRouter);
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            }
            
          })
        }).catch(err => {
            store.dispatch('LogOut').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页      
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
